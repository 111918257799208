    p{
        text-align: center;
    }
    h3{
        color:#ac75bb; 
    }
    .donkseal-pop-up-modal {
        position: fixed;
        max-width: 80%;
        height: 520px;
        top: 50%;
        left: 50%;
        padding:20px 40px;
        transform: translate(-50%, -50%) scale(0);
        border:  0.5px solid rgba(175, 175, 175, 0.115);
        border-radius: 20px;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.32);
        background-color: white;;
        z-index: 10;
        transition:  300ms ease-in-out;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
    .donkseal-pop-up-modal.active{
        transform: translate(-50%, -50%) scale(1);
    }
    .modal-background{
        opacity: 0;
        position: fixed; /* Stay in place */
        z-index: 0; /* Sit on top */
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
        transition:  300ms ease-in-out;
        pointer-events: none;
    }
    .modal-background.active{
        opacity: 1;
        pointer-events: all;
    }
    .closeModalBox{
        position: fixed;
    }
    #close-modal{
        width: 100%;
        height: 30px;
        display: flex;
        justify-content: right;
        cursor: pointer;
    }
    .tokenlogo-profile-pic{
        width: 120px;
        height: 120px;
        padding: 10px;
        border-radius: 50%;
        border: none;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.32);
    }
    .token-menu-div{
        width: 50%;
        height: 35px;
        display:flex;
        justify-content: space-evenly;
        align-items: center;
        border: none;
        border-radius: 10px;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.32);
        padding: 5px;
        font-size: 11px;
    }



@media only screen and (max-width: 500px)  { 

    .partner-swap-button{
        width: 65px;

    }
    .partner-title{
        font-size: 11px;
        text-align: left;
    }
    .partner-logo{
        width: 2.5rem;
    }
    .audit-img{
        width: 2.5rem;
    }
    .donkseal-whole-container{
        padding-right: 10px;
    }
}


@media only screen and (max-width: 400px) {

    .partner-swap-button{
        width: 60px;
        margin-right: 10px;

    }
    .partner-title{
        font-size:10px;
        text-align: left;
    }
    .partner-logo{
        width: 2.2rem;
    }
    .audit-img{
        width: 2.2rem;
    }

}