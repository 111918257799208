/*============ LAPTOP 1440PX ====================*/
/*============ LAPTOP 1440PX ====================*/
/*============ LAPTOP 1440PX ====================*/

.wave2{
    position: absolute;
    height: 100%;
    z-index: -1;
}
.wave3{
    position: absolute;
    height: 100%;
    z-index: 0;
    left: 50%;
}
.wave4{
    position: absolute;
    z-index: 0;
    width: 100%;
}
.wave5{
    position: absolute;
    z-index: 1;
    top: -3;
    width: 100%;
}
.content-wave{
    position: absolute;
    height: 100%;
    z-index: 0;
    left: 60%;
}
.layered-steps{
    position: absolute;
    height: 30rem;
    opacity: 0.1;
    z-index: 0;
    left: 0;
}
.layered-wave-one{
    position: absolute;
    width: 100%;
    z-index: 0;
    opacity: 0.1;
    top: 0;
}
.layered-wave-two{
    position: absolute;
    width: 100%;
    z-index: 0;
    opacity: 0.1;
    bottom: 0;
}
/*=====================================*/
#home-container{

    width: 100vw;
    height: 100vh;
    margin-top: -32px;
    overflow-y: scroll;
    overflow-x: hidden;
    scroll-behavior: smooth;
    scrollbar-width: auto;
    scrollbar-color: #8f54a0 #fff;
}
#home-container::-webkit-scrollbar {
    width: 2px;
    border-radius: 10px
}
#home-container::-webkit-scrollbar-thumb {
    background-color: #8f54a0;
    border-radius: 10px
}
#home-container::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 10px
}
/*=====================================*/
/*=====================================*/
/*=====================================*/
#first-box{
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    border-radius: 30% 0 40% 0;
    background-color: #8f54a015;
    justify-content: space-evenly;
}
.left-of-first-box{
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 100px;
}
.ace-logo{
    position: absolute;
    width: 35rem;
    height: 35rem;
    right: 4rem;
    top: 1rem;
    opacity: 0.1;
    z-index: -2;
}
.slogan-container{
    width: 80%;
    letter-spacing: 0.1rem;
    line-height: 3.3rem;
}
.sub-slogan-container{
    margin: 20px;
    width: 80%;
    margin: 15px;
    color:#8f54a0;
    letter-spacing: 0.02rem;
    font-size: 0.9rem;
    line-height: 1.2rem;
}
.left-of-first-box h4{
    font-size: 3rem;
    font-weight: 700;
    color:#ac75bb; 
}
.quickgo-button-container{
    width: 80%;
    height: 20%;
    display: flex;
    justify-content: left;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: 30px;
}
.quickgo-button-hugger{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
}
.quickgo-btn{
    width: 125px;
    height: 37px;
    font-weight: 100;
    color: white;
    border-radius: 10px;
    background-color: rgb(73, 73, 73);
    box-shadow: inset -1px -1px 5px 8px rgba(255, 255, 255, 0.081);
    cursor: pointer;
    /* margin: 15px; */
    font-size: 0.72rem;
}
.quickgo-btn:hover{
    transition: ease-in-out 0.3s;
    background-color: #ac75bb; 
    cursor: pointer;
}
#get-white-paper{
    background-color: rgb(66, 66, 66);
    box-shadow: inset 2px 2px -1px -1px rgba(255, 255, 255, 0.081);
}
#get-white-paper:hover{
    transition: ease-in-out 0.3s;
    background-color: #379e7b;
}
#get-scan{
    background-color: rgb(66, 66, 66);
    box-shadow: inset 2px 2px -1px -1px rgba(255, 255, 255, 0.081);
}
#get-scan:hover{
    transition: ease-in-out 0.3s;
    background-color: #842b35;
}
#get-audit{
    background-color: rgb(66, 66, 66);
    box-shadow: inset 2px 2px -1px -1px rgba(255, 255, 255, 0.081);
}
#get-audit:hover{
    transition: ease-in-out 0.3s;
    background-color: #727577;
}
.social-link-container{
    width: 50%;
    height: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    border: none;
    box-shadow: 7px 7px 1px rgba(41, 41, 41, 0.341);
    border-radius: 30px;
    background-color: rgb(57, 57, 57);
}
.social-link-hugger{
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 50%;
    background-color: rgb(40, 40, 40);
    box-shadow:  2.5px 2.5px 1px #ac75bb; 
    cursor:pointer;
    padding-top: 8px;
    padding-left: 6px;
}
.social-btn-shadow-up{ 
    position: relative;
    bottom: 5px;
    cursor: pointer;  
}
.social-btn-shadow-down{
    position: relative;
    bottom: 0px;
    cursor: pointer;
}
.telegram{
    width: 20px;
    height: 20px;
    cursor:pointer;
}
.twitter{
    width: 20px;
    height: 20px;
    margin-left: 2px;
    cursor:pointer;
}
.reddit{
    width: 20px;
    height: 20px;
    margin-left: 1px;
    cursor:pointer;
}
.instagram{
    width: 20px;
    height: 20px;
    margin-left: 2px;
    cursor:pointer;
}
.youtube{
    width: 20px;
    height: 20px;
    margin-left: 2px;
    cursor:pointer;
}
.linkedin{
    width: 20px;
    height: 20px;
    margin-right: 5px;
    cursor:pointer;
}
.github{
    width: 20px;
    height: 20px;
    margin-left: 2px;
    cursor:pointer;
}

/*=====================================*/

.right-of-first-box{
    width: 25%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 120px;
}
@keyframes logomove {
    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }
    25%{
        transform: translateY(0px) rotate(2deg);
        opacity: 1;
        border-radius:0;
    }
    50%{
        transform: translateY(0px) rotate(0deg);
        opacity: 1;
        border-radius:0;
    }
    75%{
        transform: translateY(0px) rotate(2deg);
        opacity: 1;
        border-radius:0;
    }
    100%{
        transform: translateY(0px) rotate(0deg);
        opacity: 1;
        border-radius:0;
    }
}
.donkey-logo{
    width: 22rem;
    height: 22rem;
    z-index: 1;
    animation: logomove 3s linear infinite;
}
@keyframes arrowmove {
    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }
    25%{
        transform: translateY(5px) rotate(0deg);
        opacity: 1;
        border-radius:0;
    }
    50%{
        transform: translateY(0px) rotate(0deg);
        opacity: 1;
        border-radius:0;
    }
    75%{
        transform: translateY(5px) rotate(0deg);
        opacity: 1;
        border-radius:0;
    }
    100%{
        transform: translateY(0px) rotate(0deg);
        opacity: 1;
        border-radius:0;
    }
}
#first-box-arrow{
    position: absolute;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
    bottom: 7.5rem;
    animation: arrowmove 3s linear infinite;
}
#second-box-arrow{
    position: absolute;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
    bottom: 7.5rem;
    animation: arrowmove 3s linear infinite;
}

/*=====================================*/
/*=====================================*/
/*=====================================*/

#second-box{
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color:#8f54a0;
    text-align: center;
}
.poker-chips{
    position: absolute;
    width: 10rem;
    height: 10rem;
    opacity: 0.2;
    z-index: 0;
    top: 11rem;
    right: 22rem;
}
.ticker-opacity{
    font-size: 15rem;
    opacity: 0.2;
}
.why-kishu-container{
    position: relative;
    font-size: 5rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.why-donk{

    width: 80px;
    text-align: center;
    position: relative;
    bottom: 90px;
    font-weight: 400;
}
/*=====================================*/
/*=====================================*/
/*=====================================*/
#third-box{
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.left-of-third-box{
    width: 53.1%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.card-chips{
    position: absolute;
    width: 50rem;
    height: 50rem;
    opacity: 0.4;
    bottom: 0.1rem;
    left: 5.5rem;
    z-index: -1;
}
.why-container{
    width: 20rem;
    height: 80vh;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    border: none;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.341);
    border-radius: 20px;
    background-color: rgb(57, 57, 57);
    z-index: 0;
    margin-top: 3rem;
}
.video-placeholder{
    width: 88%;
    height: 78%;
}
.video-playback{
    width: 88%;
    height: 88%;
}
.right-of-third-box{
    width: 50%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: #8f54a0;
    padding: 2rem;
    z-index: 1;
}
.right-of-third-box-hugger{
    position: absolute;
    width: 25rem;
    height: 30rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    z-index: 1;
    bottom: 13rem;
}
.right-of-third-box-title-container{
    width: 100%;
    height: 5.5rem;
    line-height: 1.3;
}
.right-of-third-box-value-container{
    text-align: left;
    width: 100%;
    height: 20rem;
    font-weight: 300;
    letter-spacing: 1.2px;
    line-height: 1.3;
    color: white;
    z-index: 1;
}
.getSwap-btn{
    position: absolute;
    bottom: 8.0rem;
    right: 23rem;
    background: #b695d3;
    padding: 10px 5px;
    width: 180px;
    border: none;
    font-size: 0.8rem;
    border-radius: 20px;
    color: white;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.396);
    cursor: pointer;
    z-index: 1;
}
.getSwap-btn:hover{
    background: #bba7cd;
    cursor: pointer;
}
.headerOne{
    letter-spacing: 1.2px;
    color: white; 
    font-size:2.4rem;
    font-weight: 300;
}
.headerTwo{
    letter-spacing: 1px;
    color: #4a3151; 
    font-size:1.9rem;
    font-weight: 650;
}
/*=====================================*/
/*=====================================*/
/*=====================================*/
#fourth-box{
    position: relative;
    width: 100vw;
    height: 90vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color:#8f54a0;
    text-align: center;
}
.blob2{
    position: absolute;
    z-index: 1;
    top: 0;
    width: 100%;
}
.blob3{
    position: absolute;
    z-index: -1;
    bottom: 0rem;
    right: 21rem;
    width: 100%;
    opacity: 0.4;
}
.cardArt{
    position: absolute;
    z-index: -1;

    bottom: 0rem;
    width: 100%;
    opacity: 0.2;
}
.fourth-box-slogan-headline-overlay{
    position: relative;
    opacity: 0.1;
    z-index: 0;
    top: 8.5rem;
}
.fourth-box-slogan-container{
    position: absolute;
    text-align: center;
    width: 30rem;
    height: 20rem;
    display: flex;
    flex-shrink: 1;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    align-items: left;
    font-weight: 400;
    font-size: 3rem;
    letter-spacing: 1.2px;
    line-height: 1.3;
    color: white;
    z-index: 1;
    bottom: 20rem;
    right: 3rem;
}
.left-of-fourth-box{
    width: 50%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
.right-of-fourth-box{
    width: 50%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
.tokenomics-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    font-weight: 300;
    letter-spacing: 1.2px;
    line-height: 1.3;
    color: white;
    z-index: 0;
}
.tokenomics-title{
    position: absolute;
    font-size: 5rem;
    font-weight: 700;
    color:#8f54a0;
    top: 4rem;
    margin-left: 4rem;
}
.tokenomics-title-opacity{
    position: absolute;
    font-size: 6.5rem;
    opacity: 0.1;
    top: 5rem;
    left: 3rem;
    font-weight: 200;
    z-index: -1;
    margin-left: 2rem;
}
.tokenomics-content-container{
    position: absolute;
    width: 100%;
    height: 100vh;
    padding: 5rem;
    line-height: 2;
    letter-spacing: 2px;
    top: 7.5rem;
    left: 0rem;
}
.tokenomics-list-heading-container{
    width: 40rem;
    text-align: center;
}
.tokenomics-bullet-title{
    font-size: 2rem;
    font-weight: 700;
    color:#8f54a0;
}
.tokenomics-list-container{
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    line-height: 1.5;
    width: 40rem;
    text-align: center;
}
/*=====================================*/
/*=====================================*/
/*=====================================*/

#security-section{
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: 100vh;
}
.left-side-of-security-section{
    width: 50%;
    height: 100vh;
}
.strongDonkey{
    position: absolute;
    width: 20rem;
    height: 22rem;
    top: 8rem;
    left: 13rem;
    animation: logomove 4s linear infinite;
}
.right-side-of-security-section{
    width: 50%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.safe-secure-container{
    width: 40rem;
    height: 40rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.safe-secure-content-hugger{
    width: 35rem;
    height: 35rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 2;
    margin-bottom: 10rem;
}
.safe-secure-title{
    font-size: 3rem;
    font-weight: 550;
    color:#8f54a0;
}
/*=====================================*/
/*=====================================*/
/*=====================================*/
#fifth-box{
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    color:#8f54a0;
    text-align: center;
}
.coming-soon-hugger{
    width: 100vw;
    height: 30%;
}
.casino-section-title{
    margin-top: 2rem;;
    color: white;
    font-size: 3rem;
}
.casino-end-title{
    margin-top: 2rem;;
    margin-top: 10px;
    font-size: 5rem;
}
.casino-vid-container{
    position: absolute;
    width: 100%;
    height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    
}
.video-hugger{
    width: 40rem;
    height: 23rem;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.341);
    border-radius: 10px;
    background-color: rgb(40, 40, 40);
    z-index: 1;
    margin-top: 2rem;
}
.casino-vid{
    width: 35rem;
    /* height: 35rem; */
    cursor: pointer;
    z-index: 1;
}
.casino-link{
    z-index: 1;
}

/*=====================================*/
/*=====================================*/
/*=====================================*/

#sixth-box{
    position: relative;
    width: 100vw;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    color:#8f54a0;
    text-align: center;
}
.left-of-sixth-box{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.team-header-container{
    width: 25%;
    font-size: 3rem;
    font-weight: 700;
    color:white;
}
.profile-hugger{
    margin-top: 1rem;
    width: 15rem;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.profile-pic-container{
    width: 9rem;
    height: 9rem;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    border: none;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.341);
    border-radius: 50%;
    background-color: rgb(57, 57, 57);
}
.dev-pics{
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
}
.dev-content-container{
    width: 100%;
    height: 32rem;
    display: flex;
    font-size: 12px;
    line-height: 1.3;
    color: white;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border: none;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.341);
    border-radius: 10px;
    background-color: rgb(57, 57, 57);
    padding: 10px;
    z-index: 1;
}
.right-of-fifth-box{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
/*=====================================*/
/*=====================================*/
/*=====================================*/
.copyrights{
    width: 100vw;
    height: 5rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #8f54a0;;
    font-weight: 200;
    font-size:1rem;
}
/*=====================================*/
/*=====================================*/
/*=====================================*/

@keyframes animate {
    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }
    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }
}

.animated-background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    z-index: -1;
    overflow: hidden;
}
.animated-background li {
    position: absolute;
    display: block;
    list-style: none;
    width: 8rem;
    height: 8rem;
    background:none;
    animation: animate 4s linear infinite;
}

.ace-card-float{
    width: 8rem;
    height: 8rem;
    opacity: 0.3;
}

.animated-background li:nth-child(0) {
    left: 60%;
    width: 8rem;
    height: 8rem;
    bottom: -142px;
    animation-delay: 1s;
}
.animated-backgroundli:nth-child(1) {
    left: 78%;
    width: 8rem;
    height: 8rem;
    bottom: -187px;
    animation-delay: 3s;
}
.animated-background li:nth-child(2) {
    left: 44%;
    width: 8rem;
    height: 8rem;
    bottom: -107px;
    animation-delay: 9s;
}
.animated-background li:nth-child(3) {
    left: 60%;
    width: 8rem;
    height: 8rem;
    bottom: -190px;
    animation-delay: 3s;
}
.animated-background li:nth-child(4) {
    left: 31%;
    width: 8rem;
    height: 8rem;
    bottom: -122px;
    animation-delay: 2s;
}
.animated-background li:nth-child(5) {
    left: 30%;
    width: 8rem;
    height: 8rem;
    bottom: -170px;
    animation-delay: 8s;
}
.animated-background li:nth-child(6) {
    left: 62%;
    width: 8rem;
    height: 8rem;
    bottom: -123px;
    animation-delay: 25s;
}
.animated-background li:nth-child(7) {
    left: 67%;
    width: 8rem;
    height: 8rem;
    bottom: -105px;
    animation-delay: 35s;
}
.animated-background li:nth-child(8) {
    left: 88%;
    width: 8rem;
    height: 8rem;
    bottom: -140px;
    animation-delay: 20s;
}
.animated-background li:nth-child(9) {
    left: 3%;
    width: 8rem;
    height: 8rem;
    bottom: -197px;
    animation-delay: 9s;
}


/*============ DESKTOP AND ABOVE ====================*/
/*============ DESKTOP AND ABOVE ====================*/
/*============ DESKTOP AND ABOVE ====================*/

@media only screen and (min-width: 1900px) {

    .slogan-container{
        width: 60%;
        letter-spacing: 0.1rem;
        line-height: 3.3rem;
        margin-right:12rem;
    }
    .quickgo-button-container{
        width: 70%;
        height: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;
        flex-shrink: 0; 
        margin-right:10rem;
    }
    .social-link-container{
        width: 50%;
        height: 80%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
        border: none;
        padding: 10px;
        box-shadow: 7px 7px 1px rgba(41, 41, 41, 0.341);
        border-radius: 30px;
        background-color: rgb(57, 57, 57);
    }
    .wave4{
        position: absolute;
        z-index: 0;
        bottom: 0;
        width: 100%;
    }
    .ace-logo{
        position: absolute;
        width: 35rem;
        height: 35rem;
        right: 12.5rem;
        top: 13rem;
        opacity: 0.1;
        z-index: -2;
    }
    /*=====================================*/
    /*=====================================*/
    /*=====================================*/
    #third-box{
        position: relative;
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .wave2{
        position: absolute;
        height: 100%;
        top: 0;
        z-index: -1;
    }
    .wave5{
        position: absolute;
        z-index: 1;
        top: 0;
        width: 100%;
    }
    .why-container{
        width: 20rem;
        height: 65vh;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
        border: none;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.341);
        border-radius: 20px;
        background-color: rgb(57, 57, 57);
        z-index: 0;
        margin-top: 20rem;
        margin-bottom: 20rem;
    }
    .card-chips{
        position: absolute;
        width: 50rem;
        height: 50rem;
        opacity: 0.4;
        bottom: 30rem;
        left: 5rem;
        z-index: -1;
        top: 20px;
    }
    .right-of-third-box-hugger{
        position: absolute;
        width: 35rem;
        height: 20rem;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        z-index: 1;
        bottom: 30rem;
    }
    .getSwap-btn{
        position: absolute;
        bottom: 26.7rem;
        right: 36rem;
        background: #b695d3;
        padding: 10px 5px;
        width: 180px;
        border: none;
        font-size: 0.8rem;
        border-radius: 20px;
        color: white;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.396);
        cursor: pointer;
        z-index: 1;
    }
    .card-chips{
        position: absolute;
        width: 50rem;
        height: 50rem;
        opacity: 0.4;
        bottom: 15rem;
        left: 0rem;
        z-index: 0;
    }
    .headerOne{
        position:absolute;
        top: -4rem;
    }
    .headerTwo{
        position:absolute;
        top: -1.5rem;
        font-size: 1.7rem;
    }
    /*=====================================*/
    /*=====================================*/
    /*=====================================*/
    #fourth-box{
        position: relative;
        width: 100vw;
        height: 90vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color:#8f54a0;
        text-align: center;
    }
    .tokenomics-title-opacity{
        position: absolute;
        font-size: 8.2rem;
        opacity: 0.1;
        top: 4rem;
        left: 1rem;
        font-weight: 200;
        z-index: -1;
        margin-left: 1.5rem;
    }
    .tokenomics-title{
        position: absolute;
        font-size: 5.8rem;
        font-weight: 700;
        color:#8f54a0;
        top: 4rem;
        left: 1.7rem;
    }
    .fourth-box-slogan-container{
        position: absolute;
        text-align: center;
        width: 55rem;
        height: 20rem;
        display: flex;
        flex-shrink: 1;
        flex-direction: column;
        justify-content: center;
        font-weight: 400;
        font-size: 3rem;
        letter-spacing: 1.2px;
        line-height: 1.3;
        color: white;
        z-index: 1;
        top: -4rem;
        right: 1rem;
    }
    .tokenomics-content-container{
        position: absolute;
        width: 100%;
        height: 100vh;
        padding: 5rem;
        line-height: 2;
        letter-spacing: 2px;
        top: 9rem;
        font-size: 1.0rem;

    }
    /*=====================================*/
    /*=====================================*/
    /*=====================================*/
    #security-section{
        position: relative;
        display: flex;
        flex-direction: row;
        width: 100vw;
        height: 100vh;
    }
    .strongDonkey{
        position: absolute;
        top: 22rem;
        left: 20rem;
    }
    .blob3{
        position: absolute;
        z-index: -1;
        bottom: 2.5rem;
        right: 35rem;
        width: 100%;
        opacity: 0.4;
        right: 30rem;
    }
    .safe-secure-container{
        width: 40rem;
        height: 40rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        font-size: 1.3rem;
        margin-top: 5rem;

    }
    .safe-secure-title{
        font-size: 2rem;
        font-weight: 550;
        color:#8f54a0;
        font-size: 3rem;
    }
    /*=====================================*/
    /*=====================================*/
    /*=====================================*/
    #fifth-box{
        position: relative;
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        color:#8f54a0;
        text-align: center;
    }
    .casino-section-title{
        margin-top: 7rem;;
        color: white;
        font-size: 3rem;
    }
    .casino-end-title{
        margin-top: 15rem;;
        margin-top: 10px;
        font-size: 5rem;
    }

}


/*============ IPAD PRO ====================*/
/*============ IPAD PRO ====================*/
/*============ IPAD PRO ====================*/

@media only screen and (max-width: 1024px) {

    .slogan-container{
        width: 70%;
        letter-spacing: 0.1rem;
        line-height: 3.3rem;
    }
    .sub-slogan-container{
        margin: 20px;
        width: 70%;
        height: 90px;
        margin: 15px;
        color:#8f54a0;
        letter-spacing: 0.02rem;
        font-size: 0.9rem;
        line-height: 1.2rem;
        padding-right: 50px;
        padding-bottom: 5px;
    }
    .quickgo-button-container{
        width: 70%;
        height: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;
        flex-shrink: 0; 
        margin-top: 25px;
        margin-right: 6rem;
    }
    .quickgo-button-hugger{
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
    }
    .quickgo-btn{
        width: 105px;
        height: 37px;
        font-weight: 100;
        color: white;
        border-radius: 10px;
        background-color: rgb(73, 73, 73);
        box-shadow: inset -1px -1px 5px 8px rgba(255, 255, 255, 0.081);
        cursor: pointer;
        font-size: 0.72rem;
    }
    .social-link-container{
        width: 35%;
        height: 85%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
        border: none;
        padding: 10px;
        box-shadow: 7px 7px 1px rgba(41, 41, 41, 0.341);
        border-radius: 30px;
        background-color: rgb(57, 57, 57);
    }
    .ace-logo{
        position: absolute;
        width: 35rem;
        height: 35rem;
        right: -2rem;
        top: 20rem;
        opacity: 0.1;
        z-index: -2;
    }
        /*=====================================*/
    /*=====================================*/
    /*=====================================*/
    .wave4{
        position: absolute;
        z-index: 0;
        bottom: 0;
        width: 100%;
    }
    /*=====================================*/
    /*=====================================*/
    /*=====================================*/
    #third-box{
        position: relative;
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .wave2{
        position: absolute;
        height: 100%;
        top: 0;
        z-index: -1;
    }
    .wave5{
        position: absolute;
        z-index: 1;
        top: 0;
        width: 100%;
    }
    .why-container{
        width: 20rem;
        height: 50vh;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
        border: none;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.341);
        border-radius: 20px;
        background-color: rgb(57, 57, 57);
        z-index: 0;
        margin-top: 25rem;
        margin-bottom: 20rem;
    }
    .card-chips{
        position: absolute;
        width: 50rem;
        height: 50rem;
        opacity: 0.4;
        bottom: 30rem;
        left: 0rem;
        z-index: -1;
    }
    .right-of-third-box-hugger{
        position: absolute;
        width: 25rem;
        height: 30rem;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        z-index: 1;
        bottom: 30rem;
    }
    .getSwap-btn{
        position: absolute;
        bottom: 24rem;
        right: 17rem;
        background: #b695d3;
        padding: 10px 5px;
        width: 180px;
        border: none;
        font-size: 0.8rem;
        border-radius: 20px;
        color: white;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.396);
        cursor: pointer;
        z-index: 1;
    }
    /*=====================================*/
    /*=====================================*/
    /*=====================================*/
    #fourth-box{
        position: relative;
        width: 100vw;
        height: 90vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color:#8f54a0;
        text-align: center;
    }
    .blob2{
    position: absolute;
    z-index: 1;
    top: 0;
    width: 100%;
    }
    .left-of-fourth-box{
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }
    .tokenomics-title{
        position: absolute;
        font-size: 5rem;
        font-weight: 700;
        color:#8f54a0;
        top: 27rem;
        left: 13rem;
    }
    .tokenomics-title-opacity{
        position: absolute;
        font-size: 6.5rem;
        opacity: 0.1;
        top: 23rem;
        left: 3rem;
        font-weight: 200;
        z-index: -1;
        margin-left: 2rem;
    }
    .tokenomics-content-container{
        position: absolute;
        width: 100%;
        height: 100vh;
        padding: 5rem;
        line-height: 2;
        letter-spacing: 2px;
        top: 30.5rem;
        left: 7.5rem;
    }
    .fourth-box-slogan-container{
        position: absolute;
        text-align: center;
        width: 25rem;
        height: 20rem;
        display: flex;
        flex-shrink: 1;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        align-items: left;
        font-weight: 400;
        font-size: 2rem;
        letter-spacing: 1.2px;
        line-height: 1.3;
        color: white;
        z-index: 1;
        top: -5rem;
        right: -3rem;
    }
    /*=====================================*/
    /*=====================================*/
    /*=====================================*/
    #security-section{
        position: relative;
        display: flex;
        flex-direction: row;
        width: 100vw;
        height: 100vh;
    }
    .left-side-of-security-section{
        width: 50%;
        height: 100vh;
    }
    .strongDonkey{
        position: absolute;
        width: 13rem;
        height: 15rem;
        top: 28rem;
        left: 8rem;
        animation: logomove 4s linear infinite;
    }
    .blob3{
        position: absolute;
        z-index: -1;
        bottom: 33rem;
        right: 17rem;
        width: 100%;
        opacity: 0.4;
    }
    .safe-secure-container{
        width: 40rem;
        height: 40rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .safe-secure-title{
        font-size: 2rem;
        font-weight: 550;
        color:#8f54a0;
    }
    .safe-secure-content-hugger{
        width: 25rem;
        height: 35rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        line-height: 2;
        margin-bottom: 13rem;
    }
    /*=====================================*/
    /*=====================================*/
    /*=====================================*/
    #fifth-box{
        position: relative;
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        color:#8f54a0;
        text-align: center;
    }
    .casino-section-title{
        margin-top: 15rem;;
        color: white;
        font-size: 3rem;
    }
    .casino-end-title{
        margin-top: 15rem;;
        margin-top: 10px;
        font-size: 5rem;
    }
    .video-hugger{
        width: 40rem;
        height: 23rem;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.341);
        border-radius: 10px;
        background-color: rgb(40, 40, 40);
        z-index: 1;
    }
    /*=====================================*/
    /*=====================================*/
    /*=====================================*/
    #sixth-box{
        position: relative;
        width: 100vw;
        height: 80vh;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        color:#8f54a0;
        text-align: center;
    }
    .team-header-container{
        width: 25%;
        font-size: 2rem;
        font-weight: 700;
        color:white;
    }
    .profile-hugger{
        margin-top: 1rem;
        width: 14rem;
        height:100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    .profile-pic-container{
        width: 8rem;
        height: 8rem;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
        border: none;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.341);
        border-radius: 50%;
        background-color: rgb(57, 57, 57);
    }
    .dev-pics{
        width: 6rem;
        height: 6rem;
        border-radius: 50%;
    }
    .dev-content-container{
        width: 100%;
        height: 32rem;
        display: flex;
        font-size: 11px;
        line-height: 1.3;
        color: white;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        border: none;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.341);
        border-radius: 10px;
        background-color: rgb(57, 57, 57);
        padding: 10px;
        z-index: 1;
    }
}

/*============ IPAD  ====================*/
/*============ IPAD ====================*/
/*============ IPAD  ====================*/

@media only screen and (max-width: 768px) {

    #first-box{
        position: relative;
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: row;
        border-radius: 30% 0 40% 0;
        background-color: #8f54a015;
        justify-content: space-evenly;
    }
    .slogan-container{
        width: 80%;
        letter-spacing: 0.1rem;
        line-height: 3.3rem;
    }
    .slogan-container h4{
        font-size: 2.5rem;
    }
    .sub-slogan-container{
        margin-left: -20px;
        margin-bottom: 35px;
        width: 68%;
        height: 90px;
        color:#8f54a0;
        letter-spacing: 0.02rem;
        font-size: 0.9rem;
        line-height: 1.2rem;
        padding-right: 50px;
        padding-bottom: 5px;
    }
    .quickgo-button-container{
        width: 60%;
        height: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;
        flex-shrink: 0; 
        margin-left: 0rem;
    }
    .quickgo-button-hugger{
        width: 65%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
    }
    .quickgo-btn{
        width: 125px;
        height: 37px;
        font-weight: 100;
        color: white;
        border-radius: 10px;
        background-color: rgb(73, 73, 73);
        box-shadow: inset -1px -1px 5px 8px rgba(255, 255, 255, 0.081);
        cursor: pointer;
        font-size: 0.72rem;
    }
    .social-link-container{
        width: 40%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
        border: none;
        padding: 7px;
        box-shadow: 7px 7px 1px rgba(41, 41, 41, 0.341);
        border-radius: 30px;
        background-color: rgb(57, 57, 57);
    }
    .ace-logo{
        position: absolute;
        width: 35rem;
        height: 35rem;
        right: -5rem;
        top: 10rem;
        opacity: 0.1;
        z-index: -2;
    }
    .social-link-hugger{
        width: 2.0rem;
        height: 2.0rem;
        border-radius: 50%;
        background-color: rgb(40, 40, 40);
        box-shadow:  2.5px 2.5px 1px #ac75bb; 
        cursor:pointer;
        padding-top: 6px;
        padding-left: 5px;
    }
    .telegram{
        width: 18px;
        height: 18px;
        cursor:pointer;
    }
    .twitter{
        width: 18px;
        height: 18px;
        margin-left: 2px;
        cursor:pointer;
    }
    .reddit{
        width: 18px;
        height: 18px;
        margin-left: 1px;
        cursor:pointer;
    }
    .instagram{
        width: 18px;
        height: 18px;
        margin-left: 2px;
        cursor:pointer;
    }
    .youtube{
        width: 18px;
        height: 18px;
        margin-left: 2px;
        cursor:pointer;
    }
    .linkedin{
        width: 18px;
        height: 18px;
        margin-right: 5px;
        cursor:pointer;
    }
    .github{
        width: 18px;
        height: 18px;
        margin-left: 2px;
        cursor:pointer;
    }
    
    /*=====================================*/
    /*=====================================*/
    /*=====================================*/
    #second-box{
        position: relative;
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color:#8f54a0;
        text-align: center;
    }
    .wave4{
        position: absolute;
        z-index: 0;
        bottom: 0;
        width: 100%;
    }
    .ticker-opacity{
        font-size: 15rem;
        opacity: 0.2;
    }
    .poker-chips{
        position: absolute;
        width: 10rem;
        height: 10rem;
        opacity: 0.2;
        z-index: 0;
        top: 11rem;
        right: 25rem;
    }
    .why-donk{
        width: 80px;
        text-align: center;
        position: relative;
        bottom: 90px;
        font-weight: 400;
        margin-right: 8rem;
    }
    /*=====================================*/
    /*=====================================*/
    /*=====================================*/
    #third-box{
        position: relative;
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .wave2{
        position: absolute;
        height: 100%;
        top: 0;
        z-index: -1;
    }
    .wave5{
        position: absolute;
        z-index: 1;
        top: 0;
        width: 100%;
    }
    .why-container{
        width: 18rem;
        height: 50vh;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
        border: none;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.341);
        border-radius: 20px;
        background-color: rgb(57, 57, 57);
        z-index: 0;
        margin-top: 25rem;
        margin-bottom: 20rem;
    }
    .card-chips{
        position: absolute;
        width: 40rem;
        height: 40rem;
        opacity: 0.4;
        bottom: 30rem;
        left: 0rem;
        z-index: 0;
    }
    .right-of-third-box-hugger{
        position: absolute;
        width: 20rem;
        height: 30rem;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        z-index: 1;
        bottom: 16rem;
        font-size: 0.8rem;
    }
    .right-of-third-box-title-container{
        width: 100%;
        height: 5.5rem;
        line-height: 1.2;
        margin-bottom: 30px;
    }
    .getSwap-btn{
        position: absolute;
        bottom: 14rem;
        right: 11rem;
        background: #b695d3;
        padding: 10px 5px;
        width: 180px;
        border: none;
        font-size: 0.8rem;
        border-radius: 20px;
        color: white;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.396);
        cursor: pointer;
        z-index: 1;
    }
    /*=====================================*/
    /*=====================================*/
    /*=====================================*/
    #fourth-box{
        position: relative;
        width: 100vw;
        height: 90vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color:#8f54a0;
        text-align: center;
    }
    .blob2{
    position: absolute;
    z-index: 1;
    top: 0;
    width: 100%;
    }
    .left-of-fourth-box{
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }
    .tokenomics-title{
        position: absolute;
        font-size: 4.5rem;
        font-weight: 700;
        color:#8f54a0;
        top: 18rem;
        left: 6rem;
    }
    .tokenomics-title-opacity{
        position: absolute;
        font-size: 5.5rem;
        opacity: 0.1;
        top: 16rem;
        left: 5rem;
        font-weight: 200;
        z-index: -1;
        margin-left: 2rem;
    }
    .tokenomics-content-container{
        position: absolute;
        text-align: center;
        width: 100%;
        height: 100vh;
        padding: 5rem;
        line-height: 2;
        letter-spacing: 2px;
        top: 20rem;
        left: 0rem;
    }
    .fourth-box-slogan-headline-overlay{
        position: relative;
        opacity: 0.1;
        z-index: 0;
        top: 6.5rem;
        right: -1rem;
    }
    .fourth-box-slogan-container{
        position: absolute;
        text-align: center;
        width: 25rem;
        height: 20rem;
        display: flex;
        flex-shrink: 1;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        align-items: left;
        font-weight: 400;
        font-size: 1.8rem;
        letter-spacing: 1.2px;
        line-height: 1.3;
        color: white;
        z-index: 1;
        top: -8rem;
        right: -5rem;
    }
    /*=====================================*/
    /*=====================================*/
    /*=====================================*/
    #security-section{
        position: relative;
        display: flex;
        flex-direction: row;
        width: 100vw;
        height: 100vh;
    }
    .left-side-of-security-section{
        width: 50%;
        height: 100vh;
    }
    .strongDonkey{
        position: absolute;
        width: 11rem;
        height: 11rem;
        top: 20rem;
        left: 5rem;
        animation: logomove 4s linear infinite;
    }
    .blob3{
        position: absolute;
        z-index: -1;
        bottom: 33rem;
        right: 12rem;
        top: 14rem;
        width: 100%;
        opacity: 0.5;
    }
    .safe-secure-container{
        width: 40rem;
        height: 40rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .safe-secure-title{
        font-size: 1.8rem;
        font-weight: 550;
        color:#8f54a0;
    }
    .safe-secure-content-hugger{
        width: 25rem;
        height: 35rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        line-height: 2;
        margin-right: 7.5rem;
    }
    /*=====================================*/
    /*=====================================*/
    /*=====================================*/
    #fifth-box{
        position: relative;
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        color:#8f54a0;
        text-align: center;
    }
    .casino-section-title{
        margin-top: 15rem;;
        color: white;
        font-size: 2rem;
    }
    .casino-end-title{
        margin-top: 15rem;;
        margin-top: 10px;
        font-size: 5rem;
    }
    .video-hugger{
        width: 40rem;
        height: 23rem;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.341);
        border-radius: 10px;
        background-color: rgb(40, 40, 40);
        z-index: 1;
    }
    /*=====================================*/
    /*=====================================*/
    /*=====================================*/
    #sixth-box{
        position: relative;
        width: 100vw;
        height: 80vh;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        color:#8f54a0;
        text-align: center;
    }
    .team-header-container{
        width: 25%;
        font-size: 1.7rem;
        font-weight: 700;
        color:white;
    }
    .profile-hugger{
        margin-top: 1rem;
        width: 13rem;
        height:80%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 15px;
    }
    .profile-pic-container{
        width: 7rem;
        height: 7rem;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
        border: none;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.341);
        border-radius: 50%;
        background-color: rgb(57, 57, 57);
    }
    .dev-pics{
        width: 6rem;
        height: 6rem;
        border-radius: 50%;
    }
    .dev-content-container{
        width: 100%;
        height: 30rem;
        display: flex;
        font-size: 11px;
        line-height: 1.3;
        color: white;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        border: none;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.341);
        border-radius: 10px;
        background-color: rgb(57, 57, 57);
        padding: 10px;
        z-index: 1;
    }
    .dev-content-container p{
        font-size: 0.5rem;
    }
}

/*============ IPHONE 6/7/8 + PORTRAIT ====================*/
/*============ IPHONE 6/7/8 + PORTRAIT ====================*/
/*============ IPHONE 6/7/8 + PORTRAIT ====================*/

@media only screen and (max-width: 500px)  { 

    .slogan-container{
        width: 90%;
        letter-spacing: 0.1rem;
        line-height: 2.3rem;
        margin-left: 1.5rem;
    }
    .slogan-container h4{
        font-size: 1.5rem;
    }
    .sub-slogan-container{
        width: 100%;
        color:#8f54a0;
        letter-spacing: 0.02rem;
        font-size: 0.65rem;
        line-height: 1.2rem;
        margin-left: 60px;
    }
    .quickgo-button-container{
        width: 100%;
        height: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;
        flex-shrink: 0; 
        margin-top: 40px;
        margin-right: 2rem;
    }
    .quickgo-button-hugger{
        width: 80%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
    }
    .quickgo-btn{
        width: 100px;
        height: 37px;
        font-weight: 100;
        color: white;
        border-radius: 10px;
        background-color: rgb(73, 73, 73);
        box-shadow: inset -1px -1px 5px 8px rgba(255, 255, 255, 0.081);
        cursor: pointer;
        font-size: 0.72rem;
    }
    .social-link-container{
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
        border: none;
        padding: 10px;
        box-shadow: 7px 7px 1px rgba(41, 41, 41, 0.341);
        border-radius: 30px;
        background-color: rgb(57, 57, 57);
    }
    .ace-logo{
        position: absolute;
        width: 20rem;
        height: 20rem;
        right: -2rem;
        top: 5rem;
        opacity: 0.1;
        z-index: -2;
    }
    .donkey-logo{
        position: absolute;
        top: 9rem;
        width: 13rem;
        height: 13rem;
        z-index: 1;
        animation: logomove 3s linear infinite;
    }

/*=====================================*/
/*=====================================*/
/*=====================================*/

    #second-box{
        position: relative;
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color:#8f54a0;
        text-align: center;
    }
    .poker-chips{
        position: absolute;
        width: 15rem;
        height: 15rem;
        opacity: 0.2;
        z-index: 0;
        top: 3rem;
        right: 0rem;
    }
    .ticker-opacity{
        position: absolute;
        top: -10rem;
        font-size: 6rem;
        opacity: 0.2;
    }
    .why-donk{
        position:absolute;
        width: 100%;
        text-align: center;
        position: relative;
        bottom: 90px;
        font-weight: 400;
        font-size: 2.6rem;
        top: -3rem;
        left: 4.3rem;
    }

/*=====================================*/
/*=====================================*/
/*=====================================*/
    #third-box{
        position: relative;
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #8962aa;;
    }
    .card-chips{
        position: absolute;
        width: 60rem;
        height: 60rem;
        opacity: 0.2;
        bottom: 0.1rem;
        left: 5.5rem;
        z-index: 0;
    }
    .right-of-third-box{
        width: 50%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        background-color: rgba(0, 0, 0, 0);
        padding: 2rem;
        z-index: 1;
    }
    .why-container{
        position: absolute;
        width: 9.5rem;
        height: 35vh;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
        border: none;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.341);
        border-radius: 20px;
        background-color: rgb(57, 57, 57);
        z-index: 1;
        top: 1rem;
        margin-bottom: 20rem;
        display:none;
    }
    .right-of-third-box-value-container{
        text-align: left;
        width: 100%;
        height: 13rem;
        font-weight: 500;
        letter-spacing: 1.2px;
        line-height: 1.3;
        color: white;
        z-index: 1;
        font-size: 0.80rem;
    }
    .getSwap-btn{
        position: absolute;
        bottom: 9.5rem;
        left: 2.5rem;
        background: #753fa4;
        padding: 10px 5px;
        width: 140px;
        border: none;
        font-size: 0.8rem;
        border-radius: 20px;
        color: white;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.396);
        cursor: pointer;
        z-index: 1;
    }
    .headerOne{
        position:absolute;
        top: 6rem;
    }
    .headerTwo{
        position:absolute;
        top: 9rem;
        font-size: 1.7rem;
    }

    /*=====================================*/
    /*=====================================*/
    /*=====================================*/
    #fourth-box{
        position: relative;
        width: 100vw;
        height: 90vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color:#8f54a0;
        text-align: center;
    }
    .blob2{
        position: absolute;
        z-index: 1;
        top: 0;
        width: 100%;
        display: none;
    }
    .tokenomics-title{
        position: absolute;
        font-size: 2.8rem;
        font-weight: 700;
        color:#8f54a0;
        top: 4rem;
        left: 0rem;
    }
    .tokenomics-title-opacity{
        position: absolute;
        font-size: 3.2rem;
        opacity: 0.1;
        top: 5rem;
        left: 1rem;
        font-weight: 200;
        z-index: -1;
        margin-left: 2.5rem;
    }
    .tokenomics-bullet-title{
        font-size: 0.9rem;
        font-weight: 700;
        color:#8f54a0;
    }
    .tokenomics-content-container{
        position: absolute;
        width: 100%;
        height: 100vh;
        padding: 5rem;
        line-height: 2;
        letter-spacing: 2px;
        font-size: 0.75rem;
        top: 5.8rem;
        left: -2rem;
    }
    .tokenomics-list-heading-container{
        width: 20rem;
        text-align: center;
    }
    .tokenomics-list-container{
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: left;
        line-height: 1.5;
        width: 20rem;
        text-align: center;
        font-size: 0.75rem;
    }
    .fourth-box-slogan-container{
        position: absolute;
        text-align: center;
        width: 25rem;
        height: 20rem;
        display: flex;
        flex-shrink: 1;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        align-items: left;
        font-weight: 400;
        font-size: 2rem;
        letter-spacing: 1.2px;
        line-height: 1.3;
        color: white;
        z-index: 1;
        top: -5rem;
        right: -3rem;
        display: none;
    }

    /*=====================================*/
    /*=====================================*/
    /*=====================================*/
    #security-section{
        position: relative;
        display: flex;
        flex-direction: row;
        width: 100vw;
        height: 100vh;
    }
    .left-side-of-security-section{
        width: 50%;
        height: 100vh;
    }
    .strongDonkey{
        position: absolute;
        width: 15rem;
        height: 15rem;
        top: 3rem;
        left: 5rem;
        animation: logomove 4s linear infinite;
    }
    .blob3{
        position: absolute;
        z-index: -1;
        bottom: 33rem;
        right: 12rem;
        top: 9rem;
        left: 1.5rem;
        width: 100%;
        opacity: 0.5;
    }
    .safe-secure-container{
        width: 35rem;
        height: 40rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        font-size: 0.8rem;
        margin-top: 25rem;
        margin-right: 3.5rem;
    }
    .safe-secure-title{
        font-size: 1.8rem;
        font-weight: 550;
        color:#8f54a0;
        
    }
    .safe-secure-content-hugger{
        width: 20rem;
        height: 35rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        line-height: 2;
        margin-right: 10rem;
    }

    /*=====================================*/
    /*=====================================*/
    /*=====================================*/
    #fifth-box{
        position: relative;
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        color:#8f54a0;
        text-align: center;
    }
    .coming-soon-hugger{
        width: 100vw;
        height: 0;
        position: absolute;
        bottom: 50rem;
    }
    .casino-section-title{
        color: white;
        font-size: 1.7rem;
        margin-left: 20px;
    }
    .casino-end-title{
        position: absolute;
        margin-top: 0.1rem;
        font-size: 1.8rem;
        width: 100%;
        left: 0rem;
    }
    .video-hugger{
        position: absolute;
        width: 20rem;
        height: 16rem;
        top: 1.5rem;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.341);
        border-radius: 10px;
        background-color: rgb(40, 40, 40);
        z-index: 1;
    }
    .casino-vid{
        width: 18rem;
        /* height: 35rem; */
        cursor: pointer;
        z-index: 1;
    }
    .casino-link{
        position:absolute;
        top: 25rem;
    }
    .cardArt{
        position: absolute;
        width: 90rem;
        height: 90rem;
        bottom: -50rem;
        right: 0rem;
    }
    .video-hugger{
        position: absolute;
        width: 20rem;
        height: 16rem;
        top: 1.5rem;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.341);
        border-radius: 10px;
        background-color: rgb(40, 40, 40);
        z-index: 1;
    }
    .casino-vid{
        width: 18rem;
        /* height: 35rem; */
        cursor: pointer;
        z-index: 1;
        display: none;
    }
    .casino-link{
        position:absolute;
        top: 13rem;
        width: 120px;
        height: 40px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: #9e64d1;
        border:none;
        border-radius: 20px;
        box-shadow: 5px 5px 10px black;
        font-size: 0.85rem;
    }

    /*=====================================*/
    /*=====================================*/
    /*=====================================*/

    .team-header-container{
        width: 100%;
        font-size: 1.7rem;
        font-weight: 700;
        color:white;
        margin-left: 0.9rem;

    }
    .left-of-sixth-box{
        position: absolute;
        width: 100vw;
        height: 80%;
        display: block;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        overflow-y: scroll;
        position:absolute;
        top: 5rem;      
    }
    .profile-hugger{
        margin-top: 1rem;
        width: 16rem;
        height:100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 15px;
    }
    .profile-pic-container{
        width: 9rem;
        height: 9rem;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
        border: none;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.341);
        border-radius: 50%;
        background-color: rgb(57, 57, 57);
    }
    .dev-pics{
        width: 8rem;
        height: 8rem;
        border-radius: 50%;
    }
    .dev-content-container{
        width: 100%;
        height: 30rem;
        display: flex;
        font-size: 15px;
        line-height: 1.3;
        color: white;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        border: none;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.341);
        border-radius: 10px;
        background-color: rgb(57, 57, 57);
        padding: 10px;
        z-index: 1;
        margin-left: 38%;

    }
    .dev-content-container p{

        font-size: 0.68rem;
    }
}



/*============ IPHONE 6/7/8 Regular PORTRAIT ====================*/
/*============ IPHONE 6/7/8 Regular PORTRAIT ====================*/
/*============ IPHONE 6/7/8 Regular PORTRAIT ====================*/

@media only screen and (max-width: 400px) {

    .slogan-container{
        width: 95%;
        letter-spacing: 0.1rem;
        line-height: 2.3rem;
        margin-left: 1.5rem;
    }
    .slogan-container h4{
        font-size: 1.5rem;
    }
    .sub-slogan-container{
        width: 100%;
        color:#8f54a0;
        letter-spacing: 0.02rem;
        font-size: 0.55rem;
        line-height: 1.2rem;
        margin-left: 55px;
        /* padding: 0; */
    }
    .quickgo-button-container{
        width: 100%;
        height: 35%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;
        flex-shrink: 0; 
        margin-right: 0.5rem;
    }
    .quickgo-button-hugger{
        width: 75%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
    }
    .quickgo-btn{
        width: 100px;
        height: 37px;
        font-weight: 100;
        color: white;
        border-radius: 10px;
        background-color: rgb(73, 73, 73);
        box-shadow: inset -1px -1px 5px 8px rgba(255, 255, 255, 0.081);
        cursor: pointer;
        font-size: 0.72rem;
    }
    .social-link-container{
        width: 50%;
        height: 90%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
        border: none;
        padding: 2px;
        box-shadow: 7px 7px 1px rgba(41, 41, 41, 0.341);
        border-radius: 30px;
        background-color: rgb(57, 57, 57);
    }
    .ace-logo{
        position: absolute;
        width: 20rem;
        height: 20rem;
        right: -2rem;
        top: 5rem;
        opacity: 0.1;
        z-index: -2;
    }
    .donkey-logo{
        position: absolute;
        top: 9rem;
        width: 13rem;
        height: 13rem;
        z-index: 1;
        animation: logomove 3s linear infinite;
    }

/*=====================================*/
/*=====================================*/
/*=====================================*/

    #second-box{
        position: relative;
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color:#8f54a0;
        text-align: center;

    }
    .poker-chips{
        position: absolute;
        width: 15rem;
        height: 15rem;
        opacity: 0.2;
        z-index: 0;
        top: 3rem;
        right: 0rem;
    }
    .ticker-opacity{
        position: absolute;
        top: -10rem;
        font-size: 6rem;
        opacity: 0.2;
    }
    .why-donk{
        position:absolute;
        width: 100%;
        text-align: center;
        position: relative;
        bottom: 90px;
        font-weight: 400;
        font-size: 2.4rem;
        top: -3rem;
    }

/*=====================================*/
/*=====================================*/
/*=====================================*/
    #third-box{
        position: relative;
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #8962aa;;
    }
    .card-chips{
        position: absolute;
        width: 60rem;
        height: 60rem;
        opacity: 0.2;
        bottom: 0.1rem;
        left: 5.5rem;
        z-index: 0;
    }
    .right-of-third-box{
        width: 50%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        background-color: rgba(0, 0, 0, 0);
        padding: 2rem;
        z-index: 1;
    }
    .why-container{
        position: absolute;
        width: 9.5rem;
        height: 35vh;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
        border: none;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.341);
        border-radius: 20px;
        background-color: rgb(57, 57, 57);
        z-index: 1;
        top: 1rem;
        margin-bottom: 20rem;
        display:none;
    }
    .right-of-third-box-value-container{
        text-align: left;
        width: 100%;
        height: 8rem;
        font-weight: 500;
        letter-spacing: 1.2px;
        line-height: 1.3;
        color: white;
        z-index: 1;
    }
    .headerOne{
        position:absolute;
        top: 8rem;
    }
    .headerTwo{
        position:absolute;
        top: 11rem;
        font-size: 1.7rem;
    }
    .getSwap-btn{
        position: absolute;
        bottom: 4.5rem;
        left: 1.5rem;
        background: #753fa4;
        padding: 10px 5px;
        width: 140px;
        border: none;
        font-size: 0.75rem;
        border-radius: 20px;
        color: white;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.396);
        cursor: pointer;
        z-index: 1;
    }

    /*=====================================*/
    /*=====================================*/
    /*=====================================*/
    #fourth-box{
        position: relative;
        width: 100vw;
        height: 90vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color:#8f54a0;
        text-align: center;
    }
    .blob2{
        position: absolute;
        z-index: 1;
        top: 0;
        width: 100%;
        display: none;
    }
    .tokenomics-title{
        position: absolute;
        font-size: 2.8rem;
        font-weight: 700;
        color:#8f54a0;
        top: 10rem;
        left: -1.4rem;
    }
    .tokenomics-title-opacity{
        position: absolute;
        font-size: 3.2rem;
        opacity: 0.1;
        top: 11rem;
        left: 1rem;
        font-weight: 200;
        z-index: -1;
        margin-left: 1.2rem;
    }
    .tokenomics-bullet-title{
        font-size: 0.9rem;
        font-weight: 700;
        color:#8f54a0;
    }
    .tokenomics-content-container{
        position: absolute;
        width: 100%;
        height: 100vh;
        padding: 5rem;
        line-height: 2;
        letter-spacing: 2px;
        font-size: 0.75rem;
        top: 9.8rem;
        left: -3.2rem;
    }
    .tokenomics-list-heading-container{
        width: 20rem;
        text-align: center;
    }
    .tokenomics-list-container{
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: left;
        line-height: 1.5;
        width: 20rem;
        text-align: center;
        font-size: 0.75rem;
    }

    /*=====================================*/
    /*=====================================*/
    /*=====================================*/
    #security-section{
        position: relative;
        display: flex;
        flex-direction: row;
        width: 100vw;
        height: 100vh;
    }
    .left-side-of-security-section{
        width: 50%;
        height: 100vh;
    }
    .strongDonkey{
        position: absolute;
        width: 12rem;
        height: 12rem;
        top: 4.5rem;
        left: 5.9rem;
        animation: logomove 4s linear infinite;
    }
    .blob3{
        position: absolute;
        z-index: -1;
        bottom: 33rem;
        right: 12rem;
        top: 9rem;
        left: 1.5rem;
        width: 100%;
        opacity: 0.5;
    }
    .safe-secure-container{
        width: 35rem;
        height: 40rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        font-size: 0.8rem;
        margin-top: 25rem;
        margin-right: 1.7rem;
    }
    .safe-secure-title{
        font-size: 1.8rem;
        font-weight: 550;
        color:#8f54a0;
        
    }
    .safe-secure-content-hugger{
        width: 20rem;
        height: 35rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        line-height: 2;
        margin-right: 10rem;
    }

    /*=====================================*/
    /*=====================================*/
    /*=====================================*/
    #fifth-box{
        position: relative;
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        color:#8f54a0;
        text-align: center;
    }
    .coming-soon-hugger{
        width: 100vw;
        height: 0;
        position: absolute;
        bottom: 50rem;
    }
    .casino-section-title{
        color: white;
        font-size: 2rem;
    }
    .casino-end-title{
        margin-top: -0.5rem;

        font-size: 1.8rem;
    }
    .video-hugger{
        position: absolute;
        width: 20rem;
        height: 16rem;
        top: 1.5rem;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.341);
        border-radius: 10px;
        background-color: rgb(40, 40, 40);
        z-index: 1;
    }
    .casino-vid{
        width: 18rem;
        /* height: 35rem; */
        cursor: pointer;
        z-index: 1;
    }
    .casino-link{
        position:absolute;
        top: 25rem;
    }
    .cardArt{
        position: absolute;
        width: 90rem;
        height: 90rem;
        bottom: -52rem;
        right: 0rem;
    }
    .video-hugger{
        position: absolute;
        width: 20rem;
        height: 16rem;
        top: 1.5rem;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.341);
        border-radius: 10px;
        background-color: rgb(40, 40, 40);
        z-index: 1;
    }
    .casino-vid{
        width: 18rem;
        /* height: 35rem; */
        cursor: pointer;
        z-index: 1;
        display: none;
    }
    .casino-link{
        position:absolute;
        top: 13rem;
        width: 120px;
        height: 40px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: #9e64d1;
        border:none;
        border-radius: 20px;
        box-shadow: 5px 5px 10px black;
        font-size: 0.85rem;
    }

    /*=====================================*/
    /*=====================================*/
    /*=====================================*/
    .left-of-sixth-box{
        position: absolute;
        width: 100vw;
        height: 90%;
        display: block;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        overflow-y: scroll;
        position:absolute;
        top: 5rem;      
    }
    .profile-hugger{
        margin-top: 1rem;
        width: 16rem;
        height:100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 15px;
    }
    .profile-pic-container{
        width: 9rem;
        height: 9rem;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
        border: none;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.341);
        border-radius: 50%;
        background-color: rgb(57, 57, 57);
    }
    .dev-pics{
        width: 8rem;
        height: 8rem;
        border-radius: 50%;
    }
    .dev-content-container{
        width: 100%;
        height: 30rem;
        display: flex;
        font-size: 15px;
        line-height: 1.3;
        color: white;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        border: none;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.341);
        border-radius: 10px;
        background-color: rgb(57, 57, 57);
        padding: 10px;
        z-index: 1;
        margin-left: 4.2rem;
    }
    .dev-content-container p{

        font-size: 0.68rem;
    }
}

/*============ IPHONE X LandScape ====================*/
/*============ IPHONE X LandScape ====================*/

@media only screen 
and (max-width: 812px)
and (orientation:landscape) {

    .slogan-container{
        width: 90%;
        letter-spacing: 0.1rem;
        line-height: 2.3rem;
        margin-top: 7rem;
        margin-left: 1.5rem;
    }
    .sub-slogan-container{
        width: 100%;
        color:#8f54a0;
        letter-spacing: 0.02rem;
        font-size: 0.65rem;
        line-height: 1.2rem;
        margin-left: 70px;
    }
    .quickgo-button-container{
        width: 100%;
        height: 35%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;
        flex-shrink: 0; 
        margin-top: 0px;
        margin-right: -2rem;
    }
    .quickgo-btn{
        width: 95px;
        height: 37px;
        font-weight: 100;
        color: white;
        border-radius: 10px;
        background-color: rgb(73, 73, 73);
        box-shadow: inset -1px -1px 5px 8px rgba(255, 255, 255, 0.081);
        cursor: pointer;
        font-size: 0.72rem;
    }
    .social-link-container{
        width: 60%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
        border: none;
        padding: 10px;
        box-shadow: 7px 7px 1px rgba(41, 41, 41, 0.341);
        border-radius: 30px;
        background-color: rgb(57, 57, 57);
    }
    .ace-logo{
        position: absolute;
        width: 20rem;
        height: 20rem;
        right: 3rem;
        top: 5rem;
        opacity: 0.1;
        z-index: -2;
    }
    .donkey-logo{
        position: absolute;
        top: 9rem;
        width: 13rem;
        height: 13rem;
        z-index: 1;
        animation: logomove 3s linear infinite;
    }
    #second-box{
        position: relative;
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color:#8f54a0;
        text-align: center;
    }
    .poker-chips{
        position: absolute;
        width: 15rem;
        height: 15rem;
        opacity: 0.2;
        z-index: 0;
        top: 3rem;
        right: 0rem;
    }
    .ticker-opacity{
        position: absolute;
        top: -10rem;
        font-size: 6rem;
        opacity: 0.2;
    }
    .why-donk{
        position:absolute;
        width: 100%;
        text-align: center;
        position: relative;
        bottom: 90px;
        font-weight: 400;
        font-size: 2.6rem;
        top: -3rem;
        left: 4.3rem;
    }

    #third-box{
        position: relative;
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #8962aa;;
    }
    .card-chips{
        position: absolute;
        width: 60rem;
        height: 60rem;
        opacity: 0.0;
        bottom: 0.1rem;
        left: 5.5rem;
        z-index: -1;
    }
    .right-of-third-box{
        width: 50%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        background-color: rgba(0, 0, 0, 0);
        padding: 2rem;
        z-index: 1;
    }
    .why-container{
        position: absolute;
        width: 9.5rem;
        height: 35vh;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
        border: none;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.341);
        border-radius: 20px;
        background-color: rgb(57, 57, 57);
        z-index: 1;
        top: 1rem;
        margin-bottom: 20rem;
        display:none;
    }
    .right-of-third-box-value-container{
        position:absolute;
        top: 40rem;
        left: 2.5rem;
        text-align: center;
        width: 100%;
        height: 10rem;
        font-weight: 500;
        letter-spacing: 1.2px;
        line-height: 1.3;
        color: white;
        z-index: 1;
        font-size: 0.80rem;
    }
    .getSwap-btn{
        position: absolute;
        bottom: 1.3rem;
        left: 23rem;
        background: #753fa4;
        padding: 10px 5px;
        width: 140px;
        border: none;
        font-size: 0.8rem;
        border-radius: 20px;
        color: white;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.396);
        cursor: pointer;
        z-index: 1;
    }
    .headerOne{
        position:absolute;
        top: 36rem;
        left: 8rem;
        font-size: 1.5rem;
    }
    .headerTwo{
        position:absolute;
        top: 37.9rem;
        font-size: 1.2rem;
        left: 8rem;
    }

    #fourth-box{
        position: relative;
        width: 100vw;
        height: 90vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color:#8f54a0;
        text-align: center;
    }
    .blob2{
        position: absolute;
        z-index: 1;
        top: 0;
        width: 100%;
        display: none;
    }
    .tokenomics-title{
        position: absolute;
        font-size: 2.8rem;
        font-weight: 700;
        color:#8f54a0;
        top: 0.5rem;
        left: 0rem;
    }
    .tokenomics-title-opacity{
        position: absolute;
        font-size: 3.2rem;
        opacity: 0.1;
        top: 1rem;
        left: 1rem;
        font-weight: 200;
        z-index: -1;
        margin-left: 2.5rem;
    }
    .tokenomics-bullet-title{
        font-size: 0.9rem;
        font-weight: 700;
        color:#8f54a0;
    }
    .tokenomics-content-container{
        position: absolute;
        width: 100%;
        height: 100vh;
        padding: 5rem;
        line-height: 2;
        letter-spacing: 2px;
        font-size: 0.55rem;
        top: 0.8rem;
        left: -2rem;
    }
    .tokenomics-list-heading-container{
        width: 20rem;
        text-align: center;
        font-size: 0.55rem;
    }
    .tokenomics-list-container{
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: left;
        line-height: 1.5;
        width: 20rem;
        text-align: center;
        font-size: 0.50rem;
    }

    #security-section{
        position: relative;
        display: flex;
        flex-direction: row;
        width: 100vw;
        height: 100vh;
    }
    .left-side-of-security-section{
        width: 50%;
        height: 100vh;
    }
    .strongDonkey{
        position: absolute;
        width: 10rem;
        height: 10rem;
        top: 5rem;
        left: 5rem;
        animation: logomove 4s linear infinite;
    }
    .blob3{
        position: absolute;
        z-index: -1;
        bottom: 33rem;
        right: 12rem;
        top: -2rem;
        left: -15rem;
        width: 100%;
        opacity: 0.5;
    }
    .safe-secure-container{
        width: 35rem;
        height: 40rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        font-size: 0.55rem;
        margin-top: 10rem;
        margin-right: 3.5rem;
    }
    .safe-secure-title{
        font-size: 1.8rem;
        font-weight: 550;
        color:#8f54a0;
        
    }
    .safe-secure-content-hugger{
        width: 20rem;
        height: 35rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        line-height: 2;
        margin-right: 10rem;
    }


    #fifth-box{
        position: relative;
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        color:#8f54a0;
        text-align: center;
    }
    .coming-soon-hugger{
        width: 100vw;
        height: 0;
        position: absolute;
        bottom: 35rem;
    }
    .casino-section-title{
        color: white;
        font-size: 1.7rem;
        margin-left: 20px;
    }
    .casino-end-title{
        position: absolute;
        margin-top: 0.1rem;
        font-size: 1.8rem;
        width: 100%;
        left: 0rem;
    }
    .video-hugger{
        position: absolute;
        width: 20rem;
        height: 16rem;
        top: 1.5rem;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.341);
        border-radius: 10px;
        background-color: rgb(40, 40, 40);
        z-index: 1;
    }
    .casino-vid{
        width: 18rem;
        /* height: 35rem; */
        cursor: pointer;
        z-index: 1;
    }
    .casino-link{
        position:absolute;
        top: 25rem;
    }
    .cardArt{
        position: absolute;
        width: 90rem;
        height: 90rem;
        bottom: -60rem;
        right: 0rem;
    }
    .video-hugger{
        position: absolute;
        width: 20rem;
        height: 16rem;
        top: 1.5rem;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.341);
        border-radius: 10px;
        background-color: rgb(40, 40, 40);
        z-index: 1;
    }
    .casino-vid{
        width: 18rem;
        /* height: 35rem; */
        cursor: pointer;
        z-index: 1;
        display: none;
    }
    .casino-link{
        position:absolute;
        top: 13rem;
        width: 120px;
        height: 40px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: #9e64d1;
        border:none;
        border-radius: 20px;
        box-shadow: 5px 5px 10px black;
        font-size: 0.85rem;
    }


    .team-header-container{
        width: 100%;
        font-size: 1.0rem;
        font-weight: 700;
        color:white;
        margin-left: 0.5rem;
        opacity: 0.0;

    }
    .left-of-sixth-box{
        position: absolute;
        width: 80vw;
        height: 80%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        position:absolute;
        padding-right: 12rem;
        top: 9rem;      
    }
    .profile-hugger{
        margin-top: 1rem;
        width: 16rem;
        height:100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 8px;
    }
    .profile-pic-container{
        width: 6rem;
        height: 6rem;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
        border: none;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.341);
        border-radius: 50%;
        background-color: rgb(57, 57, 57);
    }
    .dev-pics{
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
    }
    .dev-content-container{
        width: 12rem;
        height: 20rem;
        display: flex;
        font-size: 0.6rem;
        line-height: 1.3;
        color: white;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        border: none;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.341);
        border-radius: 10px;
        background-color: rgb(57, 57, 57);
        z-index: 1;
        margin-left: 38%;

    }
    .dev-content-container p{

        padding-top: 25px;
        font-size: 0.5rem;
    }
}
/*============ IPHONE 6/7/8 LandScape ====================*/
/*============ IPHONE 6/7/8 LandScape ====================*/
/*============ IPHONE 6/7/8 LandScape ====================*/

@media only screen 
and (max-width: 667px)
and (orientation:landscape) {

    .slogan-container{
        width: 90%;
        letter-spacing: 0.1rem;
        line-height: 2.3rem;
        margin-top: 7rem;
        margin-left: 1.5rem;
    }
    .sub-slogan-container{
        width: 100%;
        color:#8f54a0;
        letter-spacing: 0.02rem;
        font-size: 0.65rem;
        line-height: 1.2rem;
        margin-left: 70px;
    }
    .quickgo-button-container{
        width: 100%;
        height: 35%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;
        flex-shrink: 0; 
        margin-top: 0px;
        margin-right: -2rem;
    }
    .quickgo-btn{
        width: 95px;
        height: 37px;
        font-weight: 100;
        color: white;
        border-radius: 10px;
        background-color: rgb(73, 73, 73);
        box-shadow: inset -1px -1px 5px 8px rgba(255, 255, 255, 0.081);
        cursor: pointer;
        font-size: 0.72rem;
    }
    .social-link-container{
        width: 70%;
        height: 80%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
        border: none;
        padding: 10px;
        box-shadow: 7px 7px 1px rgba(41, 41, 41, 0.341);
        border-radius: 30px;
        background-color: rgb(57, 57, 57);
    }
    .ace-logo{
        position: absolute;
        width: 20rem;
        height: 20rem;
        right: 3rem;
        top: -0.9rem;
        opacity: 0.1;
        z-index: -2;
    }
    .donkey-logo{
        position: absolute;
        top: 3rem;
        width: 13rem;
        height: 13rem;
        z-index: 1;
        animation: logomove 3s linear infinite;
    }
    #second-box{
        position: relative;
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color:#8f54a0;
        text-align: center;
    }
    .poker-chips{
        position: absolute;
        width: 15rem;
        height: 15rem;
        opacity: 0.2;
        z-index: 0;
        top: 3rem;
        right: 0rem;
    }
    .ticker-opacity{
        position: absolute;
        top: -6rem;
        font-size: 6rem;
        opacity: 0.2;
    }
    .why-donk{
        position:absolute;
        width: 100%;
        text-align: center;
        position: relative;
        bottom: 90px;
        font-weight: 400;
        font-size: 2.6rem;
        top: -0.5rem;
        left: 4.3rem;
    }

    #third-box{
        position: relative;
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #8962aa;;
    }
    .card-chips{
        position: absolute;
        width: 60rem;
        height: 60rem;
        opacity: 0.0;
        bottom: 0.1rem;
        left: 5.5rem;
        z-index: -1;
    }
    .right-of-third-box{
        width: 50%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        background-color: rgba(0, 0, 0, 0);
        padding: 2rem;
        z-index: 1;
    }
    .why-container{
        position: absolute;
        width: 9.5rem;
        height: 35vh;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
        border: none;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.341);
        border-radius: 20px;
        background-color: rgb(57, 57, 57);
        z-index: 1;
        top: 1rem;
        margin-bottom: 20rem;
        display:none;
    }
    .right-of-third-box-value-container{
        position:absolute;
        top: 29rem;
        left: 2.5rem;
        text-align: center;
        width: 100%;
        height: 10rem;
        font-weight: 500;
        letter-spacing: 1.2px;
        line-height: 1.3;
        color: white;
        z-index: 1;
        font-size: 0.60rem;
    }
    .getSwap-btn{
        position: absolute;
        bottom: 1.3rem;
        left: 19rem;
        background: #753fa4;
        padding: 10px 5px;
        width: 140px;
        border: none;
        font-size: 0.8rem;
        border-radius: 20px;
        color: white;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.396);
        cursor: pointer;
        z-index: 1;
        font-weight: 200;
    }
    .headerOne{
        position:absolute;
        top: 25.59rem;
        left: 8rem;
        font-size: 1.0rem;
        font-weight: 500;
    }
    .headerTwo{
        position:absolute;
        top: 26.9rem;
        font-size: 0.8rem;
        left: 8rem;
    }

    #fourth-box{
        position: relative;
        width: 100vw;
        height: 90vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color:#8f54a0;
        text-align: center;
    }
    .blob2{
        position: absolute;
        z-index: 1;
        top: 0;
        width: 100%;
        display: none;
    }
    .tokenomics-title{
        position: absolute;
        font-size: 2.8rem;
        font-weight: 700;
        color:#8f54a0;
        top: 0.5rem;
        left: 0rem;
    }
    .tokenomics-title-opacity{
        position: absolute;
        font-size: 3.2rem;
        opacity: 0.1;
        top: 1rem;
        left: 1rem;
        font-weight: 200;
        z-index: -1;
        margin-left: 2.5rem;
    }
    .tokenomics-bullet-title{
        font-size: 0.9rem;
        font-weight: 700;
        color:#8f54a0;
    }
    .tokenomics-content-container{
        position: absolute;
        width: 100%;
        height: 100vh;
        padding: 5rem;
        line-height: 2;
        letter-spacing: 2px;
        font-size: 0.55rem;
        top: 0.8rem;
        left: -2rem;
    }
    .tokenomics-list-heading-container{
        width: 20rem;
        text-align: center;
        font-size: 0.55rem;
    }
    .tokenomics-list-container{
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: left;
        line-height: 1.5;
        width: 20rem;
        text-align: center;
        font-size: 0.50rem;
    }
    .fourth-box-slogan-headline-overlay{
        position: relative;
        opacity: 0.1;
        z-index: 0;
        top: 6.5rem;
        right: -1rem;
    }
    .fourth-box-slogan-container{
        position: absolute;
        text-align: center;
        width: 10rem;
        height: 20rem;
        display: flex;
        flex-shrink: 1;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        align-items: left;
        font-weight: 400;
        font-size: 0.9rem;
        letter-spacing: 1.2px;
        line-height: 1.3;
        color: white;
        z-index: 1;
        top: -3rem;
        right: 5rem;
    }

    #security-section{
        position: relative;
        display: flex;
        flex-direction: row;
        width: 100vw;
        height: 100vh;
    }
    .left-side-of-security-section{
        width: 50%;
        height: 100vh;
    }
    .strongDonkey{
        position: absolute;
        width: 10rem;
        height: 10rem;
        top: 5rem;
        left: 5rem;
        animation: logomove 4s linear infinite;
    }
    .blob3{
        position: absolute;
        z-index: -1;
        bottom: 33rem;
        right: 12rem;
        top: -2rem;
        left: -10rem;
        width: 100%;
        opacity: 0.5;
    }
    .safe-secure-container{
        width: 35rem;
        height: 40rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        font-size: 0.55rem;
        margin-top: 10rem;
        margin-right: 0rem;
    }
    .safe-secure-title{
        font-size: 1.8rem;
        font-weight: 550;
        color:#8f54a0;
        
    }
    .safe-secure-content-hugger{
        width: 20rem;
        height: 35rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        line-height: 2;
        margin-right: 10rem;
    }


    #fifth-box{
        position: relative;
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        color:#8f54a0;
        text-align: center;
    }
    .coming-soon-hugger{
        width: 100vw;
        height: 0;
        position: absolute;
        bottom: 35rem;
    }
    .casino-section-title{
        color: white;
        font-size: 1.7rem;
        margin-left: 20px;
    }
    .casino-end-title{
        position: absolute;
        margin-top: 0.1rem;
        font-size: 1.8rem;
        width: 100%;
        left: 0rem;
    }
    .video-hugger{
        position: absolute;
        width: 20rem;
        height: 16rem;
        top: 1.5rem;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.341);
        border-radius: 10px;
        background-color: rgb(40, 40, 40);
        z-index: 1;
    }
    .casino-vid{
        width: 18rem;
        /* height: 35rem; */
        cursor: pointer;
        z-index: 1;
    }
    .casino-link{
        position:absolute;
        top: 25rem;
    }
    .cardArt{
        position: absolute;
        width: 90rem;
        height: 90rem;
        bottom: -60rem;
        right: 0rem;
    }
    .video-hugger{
        position: absolute;
        width: 20rem;
        height: 16rem;
        top: 1.5rem;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.341);
        border-radius: 10px;
        background-color: rgb(40, 40, 40);
        z-index: 1;
    }
    .casino-vid{
        width: 18rem;
        /* height: 35rem; */
        cursor: pointer;
        z-index: 1;
        display: none;
    }
    .casino-link{
        position:absolute;
        top: 13rem;
        width: 120px;
        height: 40px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: #9e64d1;
        border:none;
        border-radius: 20px;
        box-shadow: 5px 5px 10px black;
        font-size: 0.85rem;
    }

    #sixth-box{
        height: 100vh;
    }
    .team-header-container{
        width: 100%;
        font-size: 1.0rem;
        font-weight: 700;
        color:white;
        margin-left: 0.5rem;
        opacity: 0.0;

    }
    .left-of-sixth-box{
        position: absolute;
        width: 80vw;
        height: 80%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        position:absolute;
        padding-right: 12rem;
        top: 9rem;  

    }
    .profile-hugger{
        margin-top: 1rem;
        width: 16rem;
        height:100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 8px;
    }
    .profile-pic-container{
        width: 6rem;
        height: 6rem;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
        border: none;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.341);
        border-radius: 50%;
        background-color: rgb(57, 57, 57);
    }
    .dev-pics{
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
    }
    .dev-content-container{
        width: 12rem;
        height: 100%;
        display: flex;
        font-size: 0.7rem;    
        line-height: 1.2;
        color: white;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        border: none;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.341);
        border-radius: 10px;
        background-color: rgb(57, 57, 57);
        z-index: 1;
        margin-left: 38%;

    }
    .dev-content-container p{
        padding-top: 2px;
        font-size: 0.5rem;

    }
}

@media only screen 
and (max-width: 736px)
and (orientation:landscape) {

    .slogan-container{
        width: 90%;
        letter-spacing: 0.1rem;
        line-height: 2.3rem;
        margin-top: 7rem;
        margin-left: 1.5rem;
    }
    .sub-slogan-container{
        width: 100%;
        color:#8f54a0;
        letter-spacing: 0.02rem;
        font-size: 0.65rem;
        line-height: 1.2rem;
        margin-left: 70px;
    }
    .quickgo-button-container{
        width: 100%;
        height: 35%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;
        flex-shrink: 0; 
        margin-top: 0px;
        margin-right: -2rem;
    }
    .quickgo-btn{
        width: 95px;
        height: 37px;
        font-weight: 100;
        color: white;
        border-radius: 10px;
        background-color: rgb(73, 73, 73);
        box-shadow: inset -1px -1px 5px 8px rgba(255, 255, 255, 0.081);
        cursor: pointer;
        font-size: 0.72rem;
    }
    .social-link-container{
        width: 70%;
        height: 80%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
        border: none;
        padding: 10px;
        box-shadow: 7px 7px 1px rgba(41, 41, 41, 0.341);
        border-radius: 30px;
        background-color: rgb(57, 57, 57);
    }
    .ace-logo{
        position: absolute;
        width: 20rem;
        height: 20rem;
        right: 3rem;
        top: -0.9rem;
        opacity: 0.1;
        z-index: -2;
    }
    .donkey-logo{
        position: absolute;
        top: 3rem;
        width: 13rem;
        height: 13rem;
        z-index: 1;
        animation: logomove 3s linear infinite;
    }
    #second-box{
        position: relative;
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color:#8f54a0;
        text-align: center;
    }
    .poker-chips{
        position: absolute;
        width: 15rem;
        height: 15rem;
        opacity: 0.2;
        z-index: 0;
        top: 3rem;
        right: 0rem;
    }
    .ticker-opacity{
        position: absolute;
        top: -6rem;
        font-size: 6rem;
        opacity: 0.2;
    }
    .why-donk{
        position:absolute;
        width: 100%;
        text-align: center;
        position: relative;
        bottom: 90px;
        font-weight: 400;
        font-size: 2.6rem;
        top: -0.5rem;
        left: 4.3rem;
    }

    #third-box{
        position: relative;
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #8962aa;;
    }
    .card-chips{
        position: absolute;
        width: 60rem;
        height: 60rem;
        opacity: 0.0;
        bottom: 0.1rem;
        left: 5.5rem;
        z-index: -1;
    }
    .right-of-third-box{
        width: 50%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        background-color: rgba(0, 0, 0, 0);
        padding: 2rem;
        z-index: 1;
    }
    .why-container{
        position: absolute;
        width: 9.5rem;
        height: 35vh;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
        border: none;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.341);
        border-radius: 20px;
        background-color: rgb(57, 57, 57);
        z-index: 1;
        top: 1rem;
        margin-bottom: 20rem;
        display:none;
    }
    .right-of-third-box-value-container{
        position:absolute;
        top: 30rem;
        left: -10rem;
        text-align: center;
        width: 40rem;;
        height: 10rem;
        font-weight: 500;
        letter-spacing: 1.2px;
        line-height: 1.3;
        color: white;
        z-index: 1;
        font-size: 0.65rem;
    }
    .getSwap-btn{
        position: absolute;
        bottom: 5rem;
        left: 19.5rem;
        background: #753fa4;
        padding: 10px 5px;
        width: 140px;
        border: none;
        font-size: 0.8rem;
        border-radius: 20px;
        color: white;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.396);
        cursor: pointer;
        z-index: 1;
        font-weight: 200;
    }
    .headerOne{
        position:absolute;
        top: 26rem;
        left: 2rem;
        font-size: 1.4rem;
        font-weight: 500;
    }
    .headerTwo{
        position:absolute;
        top: 27.5rem;
        font-size: 1.4rem;
        left: 2rem;
        width: 30rem;
    }

    #fourth-box{
        position: relative;
        width: 100vw;
        height: 90vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color:#8f54a0;
        text-align: center;
    }
    .blob2{
        position: absolute;
        z-index: 1;
        top: 0;
        width: 100%;
        display: none;
    }
    .tokenomics-title{
        position: absolute;
        font-size: 2.8rem;
        font-weight: 700;
        color:#8f54a0;
        top: 0.5rem;
        left: 0rem;
    }
    .tokenomics-title-opacity{
        position: absolute;
        font-size: 3.2rem;
        opacity: 0.1;
        top: 1rem;
        left: 1rem;
        font-weight: 200;
        z-index: -1;
        margin-left: 2.5rem;
    }
    .tokenomics-bullet-title{
        font-size: 0.9rem;
        font-weight: 700;
        color:#8f54a0;
    }
    .tokenomics-content-container{
        position: absolute;
        width: 100%;
        height: 100vh;
        padding: 5rem;
        line-height: 2;
        letter-spacing: 2px;
        font-size: 0.55rem;
        top: 0.8rem;
        left: -2rem;
    }
    .tokenomics-list-heading-container{
        width: 20rem;
        text-align: center;
        font-size: 0.55rem;
    }
    .tokenomics-list-container{
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: left;
        line-height: 1.5;
        width: 20rem;
        text-align: center;
        font-size: 0.50rem;
    }
    .fourth-box-slogan-headline-overlay{
        position: relative;
        opacity: 0.1;
        z-index: 0;
        top: 6.5rem;
        right: -1rem;
    }
    .fourth-box-slogan-container{
        position: absolute;
        text-align: center;
        width: 10rem;
        height: 20rem;
        display: flex;
        flex-shrink: 1;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        align-items: left;
        font-weight: 400;
        font-size: 0.9rem;
        letter-spacing: 1.2px;
        line-height: 1.3;
        color: white;
        z-index: 1;
        top: -3rem;
        right: 5rem;
    }

    #security-section{
        position: relative;
        display: flex;
        flex-direction: row;
        width: 100vw;
        height: 100vh;
    }
    .left-side-of-security-section{
        width: 50%;
        height: 100vh;
    }
    .strongDonkey{
        position: absolute;
        width: 10rem;
        height: 10rem;
        top: 5rem;
        left: 5rem;
        animation: logomove 4s linear infinite;
    }
    .blob3{
        position: absolute;
        z-index: -1;
        bottom: 33rem;
        right: 12rem;
        top: -2rem;
        left: -10rem;
        width: 100%;
        opacity: 0.5;
    }
    .safe-secure-container{
        width: 35rem;
        height: 40rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        font-size: 0.55rem;
        margin-top: 10rem;
        margin-right: 0rem;
    }
    .safe-secure-title{
        font-size: 1.8rem;
        font-weight: 550;
        color:#8f54a0;
        
    }
    .safe-secure-content-hugger{
        width: 20rem;
        height: 35rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        line-height: 2;
        margin-right: 10rem;
    }


    #fifth-box{
        position: relative;
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        color:#8f54a0;
        text-align: center;
    }
    .coming-soon-hugger{
        width: 100vw;
        height: 0;
        position: absolute;
        bottom: 35rem;
    }
    .casino-section-title{
        color: white;
        font-size: 1.7rem;
        margin-left: 20px;
    }
    .casino-end-title{
        position: absolute;
        margin-top: 0.1rem;
        font-size: 1.8rem;
        width: 100%;
        left: 0rem;
    }
    .video-hugger{
        position: absolute;
        width: 20rem;
        height: 16rem;
        top: 1.5rem;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.341);
        border-radius: 10px;
        background-color: rgb(40, 40, 40);
        z-index: 1;
    }
    .casino-vid{
        width: 18rem;
        /* height: 35rem; */
        cursor: pointer;
        z-index: 1;
    }
    .casino-link{
        position:absolute;
        top: 25rem;
    }
    .cardArt{
        position: absolute;
        width: 90rem;
        height: 90rem;
        bottom: -60rem;
        right: 0rem;
    }
    .video-hugger{
        position: absolute;
        width: 20rem;
        height: 16rem;
        top: 1.5rem;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.341);
        border-radius: 10px;
        background-color: rgb(40, 40, 40);
        z-index: 1;
    }
    .casino-vid{
        width: 18rem;
        /* height: 35rem; */
        cursor: pointer;
        z-index: 1;
        display: none;
    }
    .casino-link{
        position:absolute;
        top: 13rem;
        width: 120px;
        height: 40px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: #9e64d1;
        border:none;
        border-radius: 20px;
        box-shadow: 5px 5px 10px black;
        font-size: 0.85rem;
    }

    #sixth-box{
        height: 100vh;
    }
    .team-header-container{
        width: 100%;
        font-size: 1.0rem;
        font-weight: 700;
        color:white;
        margin-left: 0.5rem;
        opacity: 0.0;

    }
    .left-of-sixth-box{
        position: absolute;
        width: 80vw;
        height: 80%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        position:absolute;
        padding-right: 12rem;
        top: 9rem;  

    }
    .profile-hugger{
        margin-top: 1rem;
        width: 16rem;
        height:100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 8px;
    }
    .profile-pic-container{
        width: 6rem;
        height: 6rem;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
        border: none;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.341);
        border-radius: 50%;
        background-color: rgb(57, 57, 57);
    }
    .dev-pics{
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
    }
    .dev-content-container{
        width: 12rem;
        height: 100%;
        display: flex;
        font-size: 0.7rem;    
        line-height: 1.2;
        color: white;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        border: none;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.341);
        border-radius: 10px;
        background-color: rgb(57, 57, 57);
        z-index: 1;
        margin-left: 38%;

    }
    .dev-content-container p{
        padding-top: 2px;
        font-size: 0.5rem;

    }
}