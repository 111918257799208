.headerPhoto-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75%;
  height: 400px;
  border: none;
  border-radius: 10px;;
  margin-top: -3%;
  margin-bottom: -1%;
  /* box-shadow: inset 2px 1px 18px rgba(140, 76, 170, 0.279); */
  margin-left: 12.5%;
}
.headerPhoto {
  width: 750px;
  height: 450px;
}
.vid-container{
  width: 100vw;
  height: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background: #232526;
  background: -webkit-linear-gradient(to right, #414345, #232526);
  background: linear-gradient(to right, #414345, #232526);
}
.youtube-hugger{
  width: 35rem;
  height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 10px;;
  box-shadow: inset 2px 1px 18px rgba(140, 76, 170, 0.279);
  margin-top: 25px;
  padding-bottom: 20px;
}

.disclaimer{
  text-align: center;
  margin: 1.5rem auto;
  padding: 1.5rem;
  width: 45%;
  /* height: 13rem; */
  font-weight: 500;
  letter-spacing: 1.2px;
  line-height: 1.3;
  color: white;
  /* z-index: 1; */
  font-size: 0.80rem;
  box-shadow: inset 2px 1px 18px rgba(140, 76, 170, 0.279);
}
.casino-vid-tab{
  width: 28rem;
  /* height: 35rem; */
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
  z-index: 1;
}

.layered-wave-one{
  position: absolute;
  width: 100%;
  z-index: 0;
  top: 0;

  }
.layered-wave-two{
  position: absolute;
width: 100%;
z-index: 0;
bottom: 0;
}

.gameContainer{
  position: relative;
  margin: 20px auto;
  height: 642px; /*606?*/
}
.container_100{
  position: relative;
  margin: 20px auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 400px;
}
.animated-logo{
  width: 22rem;
  height: 22rem;
  z-index: 1;
  animation: logomove 3s linear infinite;
}
.gameIframe{
  height: 100%;
  width: 100%;
}

.casinoSelectButton{
  width: 10rem;
  border-radius: 10px;
  margin: 5px;
  cursor: pointer;
  box-shadow: none !important;
  border: 2px solid #232526 !important;
  transition: opacity 0.2s, transform 0.2s;
}

.casinoSelectButton:hover{
  opacity: 70%;
  transform: scale(1.03);
}

@media only screen and (max-width: 500px) {
  .headerPhoto {
    width: 325px;
    height: 250px;
  }
  .vid-container{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    overflow: scroll;
    align-items: center;
    background: #232526;
    background: -webkit-linear-gradient(to right, #414345, #232526);
    background: linear-gradient(to right, #414345, #232526);
  }
  .youtube-hugger{
    width: 25rem;
    height: 15rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .casino-vid-tab{
    width: 18rem;
    /* height: 25rem; */
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
    z-index: 1;
  }


}
